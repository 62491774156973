<!--荣誉殿堂-->
<template>
  <div class="HonorAndCommendation" >
    <el-row>
      <AppPagination
          v-show="total > 0"
          :limit.sync="listQuery.limit"
          :page.sync="listQuery.page"
          :total="total"
          @pagination="getList"
      />
    </el-row>
    <el-row :gutter="20">
      <el-col style="margin-bottom: 20px" :md="6" :xs="12" :key="index" v-for="(item, index) in list">
        <el-card :body-style="{ padding: '0px' }">
          <el-image style="width: 100%; display: block; cursor:pointer;" :src="item.picture" @click="viewBtn(item)" />
          <div style="padding: 14px;">
            <div style="width: 100%; float: left; text-align: left">
              <span style="word-break: break-all;">{{ item.memberName }}</span>
            </div>
            <div style="clear: both"></div>
            <el-button type="text" style="color: red; float: right; padding-top: 0" class="iconfont icon-aixin_shixin">999+</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { pageByDTO } from '@/api/ic/honourList'
import AppPagination from "@/components/Pagination/AppPagination"
import '../../assets/iconfont/iconfont.css'
export default {
  name: "HonorAndCommendation",
  components: { AppPagination },
  data() {
    return {
      showWidth:0,  //屏幕可视宽度
      list: [],
      listQuery: { // 查询条件
        page: 1,
        limit: 20
      },
      total: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      // 开始加载loading
      let loading = this.$loading({
        lock: true, //lock的修改符--默认是false
        text: this.$t('app.loading') + '...', //显示在加载图标下方的加载文案
        background: "rgba(0,0,0,0.4)", //遮罩层颜色
        spinner: "el-icon-loading", //自定义加载图标类名
      });
      pageByDTO({ ...this.listQuery }).then(res => {
        this.list = res.data.records
        this.total = Number(res.data.total)
        loading.close()
      }).catch(err => {
        loading.close()
        console.error(err)
      })
    },
    viewBtn(item){
      this.$router.push({name: 'HonorPersonal', params: {'id': item.id}})
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
