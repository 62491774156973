import request from '@/utils/request'

// 荣誉殿堂 分页  代办处/经销商
export function pageByDTO(query) {
    return request({
        url: '/honourList/pageByDTO',
        method: 'get',
        params: query
    })
}

// 荣誉殿堂 集合  代办处/经销商
export function listByDTO(query) {
    return request({
        url: '/honourList/listByDTO',
        method: 'get',
        params: query
    })
}

// 荣誉殿堂 查看  代办处/经销商
export function viewById(query) {
    return request({
        url: '/honourList/viewById',
        method: 'get',
        params: query
    })
}

// 荣誉殿堂 点赞  代办处/经销商
export function giveLikeById(query) {
    return request({
        url: '/honourList/giveLikeById',
        method: 'get',
        params: query
    })
}
